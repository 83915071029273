.listing-visitor {
    display: grid;
    grid-template-columns: min(27.55%, 7.5rem) 1fr;
    align-items: start;
    max-width: 37.5rem;
    margin-left: auto;
    margin-right: auto;
    background-color: var(--bs-white);
    border: 0.0625rem solid var(--bs-gray-100);
    border-radius: 0.25rem;
    box-shadow: var(--box-shadow);

    .listings &:not(:last-of-type) {
        margin-bottom: 1rem;
    }

    &:has(.listing-visitor__action) {
        grid-template-columns: min(30%, 7.5rem) 1fr min(25%, 5rem);
    }

    &:has(.listing-visitor__ribbon) {
        position: relative;
        z-index: 0;
        margin-top: 0.625rem;
    }

    @media only screen and (min-width: 768px) {
        grid-template-columns: min(30%, 10rem) 1fr;

        &:has(.listing-visitor__action) {
            grid-template-columns: min(30%, 10rem) 1fr min(17.5%, 6rem);
        }

        &:has(.listing-visitor__ribbon) {
            margin-top: 0.825rem;
        }
    }

    @media only screen and (min-width: 1200px) {
        max-width: 50rem;

        &:has(.listing-visitor__action) {
            grid-template-columns: min(30%, 10rem) 1fr min(20%, 10rem);
        }
    }
}

.listing-visitor__header {
    padding-top: 0.5rem;
    padding-left: 0.5rem;

    @media only screen and (min-width: 1200px) {
        padding-top: 1rem;
        padding-left: 1rem;
    }

    a {
        display: flex;
        align-items: start;
        width: 100%;
        aspect-ratio: 16 / 9;
        margin-bottom: 0.5rem;
    }

    img,
    amp-img {
        display: block;
        flex: 1;
        width: 100%;
        object-fit: contain;
    }
}

.listing-visitor__content {
    position: relative;
    padding: 0.5rem;
    line-height: 1.25;

    .listing-visitor:has(.listing-visitor__ribbon) & {
        padding-top: 1.75rem;

        @media only screen and (min-width: 768px) {
            padding-top: 2.75rem;
        }

        .btn {
            margin-top: -1.25rem;

            @media only screen and (min-width: 768px) {
                margin-top: -2.25rem;
            }
        }
    }

    h3,
    p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: 500;
        color: var(--bs-gray-700);

        @media only screen and (min-width: 768px) {
            font-size: 1rem;
        }

        @media only screen and (min-width: 1200px) {
            font-size: 1.125rem;
        }
    }

    h3 {
        text-transform: uppercase;
        margin-right: 0.25rem;
        color: var(--bs-blue);
        font-weight: 600;
    }

    .btn {
        float: right;
        margin-left: 0.5rem;
    }
}

.listing-visitor__action {
    .btn {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1rem 0.5rem;
        font-size: 1.375rem;
        font-weight: 700;
        border-radius: 0 0.25rem 0 0;

        @media only screen and (min-width: 768px) {
            height: auto;
            font-size: 1.5rem;
            line-height: 0.9;
            padding: 1rem;
        }

        @media only screen and (min-width: 1200px) {
            font-size: 1.875rem;
            padding: 1rem 2rem;
        }
    }
}

.listing-visitor__intro,
.listing-visitor__footer {
    grid-column: 1 / -1;
    padding: 0.5rem;

    @media only screen and (min-width: 1200px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.listing-visitor__intro {
    background-color: var(--bs-white);
    color: var(--bs-black);
}

.listing-visitor__footer {
    background-color: var(--bs-gray-100);
    border-radius: 0 0 0.25rem 0.25rem;

    p {
        height: 2rem;
        overflow-y: scroll;
    }
}

.listing-visitor__ribbon {
    position: absolute;
    top: -0.6875rem;
    left: 50%;
    z-index: 1;
    width: 166px;
    height: 40px;
    transform: translateX(-50%);

    @media only screen and (min-width: 768px) {
        top: -1rem;
        width: 250px;
        height: 60px;
    }

    img {
        display: block;
        width: 100%;
        height: auto;
    }
}
