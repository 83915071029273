/* Border - Inherit */
.border-inherit {
    border-color: inherit !important;
}

/* Border - Colors */
.border-gray-100 {
    border-color: var(--bs-gray-100);
}

.border-gray-200 {
    border-color: var(--bs-gray-200);
}

.border-gray-300 {
    border-color: var(--bs-gray-300);
}

.border-gray-400 {
    border-color: var(--bs-gray-400);
}

.border-gray-500 {
    border-color: var(--bs-gray-500);
}

.border-gray-600 {
    border-color: var(--bs-gray-600);
}

.border-gray-700 {
    border-color: var(--bs-gray-700);
}

.border-gray-800 {
    border-color: var(--bs-gray-800);
}

.border-gray-900 {
    border-color: var(--bs-gray-900);
}

.border-red {
    border-color: var(--bs-red);
}

.border-red-light {
    border-color: var(--bs-red-light);
}

.border-red-summer {
    border-color: var(--bs-red-summer);
}

.border-orange {
    border-color: var(--bs-orange);
}

.border-gold {
    border-color: var(--bs-gold);
}

.border-yellow {
    border-color: var(--bs-yellow);
}

.border-yellow-summer {
    border-color: var(--bs-yellow-summer);
}

.border-yellow-highlight {
    border-color: var(--bs-yellow-highlight);
}

.border-lemon {
    border-color: var(--bs-lemon);
}

.border-green {
    border-color: var(--bs-green);
}

.border-green-light {
    border-color: var(--bs-green-light);
}

.border-sky {
    border-color: var(--bs-sky);
}

.border-cyan {
    border-color: var(--bs-cyan);
}

.border-blue {
    border-color: var(--bs-blue);
}

.border-blue-alt {
    border-color: var(--bs-blue-alt);
}

.border-blue-light {
    border-color: var(--bs-blue-light);
}

.border-steel {
    border-color: var(--bs-steel);
}

.border-primary {
    border-color: var(--bs-primary);
}

.border-primary-light {
    border-color: var(--bs-primary-light);
}

.border-primary-highlight {
    border-color: var(--bs-primary-highlight);
}

.border-primary-contrast {
    border-color: var(--bs-primary-contrast);
}

.border-white {
    border-color: var(--bs-white);
}

.border-black {
    border-color: var(--bs-black);
}
