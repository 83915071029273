[data-rewards-theme='bingoport-uk'] {
    /* Theme RGB values */
    --bs-primary-rgb: 134, 56, 229;
    --bs-primary-light-rgb: 152, 82, 243;
    --bs-primary-highlight-rgb: 244, 235, 254;
    --bs-primary-contrast-rgb: var(--bs-white-rgb);

    --bs-secondary-rgb: 236, 253, 111;
    --bs-secondary-light-rgb: 245, 254, 180;
    --bs-secondary-highlight-rgb: 249, 253, 216;
    --bs-secondary-contrast-rgb: var(--bs-black-rgb);

    /* Theme color variables */
    --bs-primary: rgb(var(--bs-primary-rgb));
    --bs-primary-light: rgb(var(--bs-primary-light-rgb));
    --bs-primary-highlight: rgb(var(--bs-primary-highlight-rgb));
    --bs-primary-contrast: rgb(var(--bs-primary-contrast-rgb));

    --bs-secondary: rgb(var(--bs-secondary-rgb));
    --bs-secondary-light: rgb(var(--bs-secondary-light-rgb));
    --bs-secondary-highlight: rgb(var(--bs-secondary-highlight-rgb));
    --bs-secondary-contrast: rgb(var(--bs-secondary-contrast-rgb));

    /* Links */
    --bs-link-color: var(--bs-blue-light);
    --bs-link-decoration: underline;

    /* Gradient (brand) */
    --bs-gradient-primary: linear-gradient(
        180deg,
        var(--bs-primary-light) 0%,
        var(--bs-primary) 100%
    );

    --bs-gradient-secondary: linear-gradient(
        180deg,
        var(--bs-secondary-light) 0%,
        var(--bs-secondary) 100%
    );
    /* Headings - Landing */
    --heading-landing-color: var(--bs-secondary);
    --heading-landing-bold-color: var(--bs-secondary);

    /* Listings - Primary */
    --listing-primary-background: url('../../images/backgrounds/bingoport-listing.webp')
        top center/100% 100%;
    --listing-primary-color: var(--bs-black);
    --listing-primary-bold: var(--bs-red-light);
    --listing-primary-link: var(--bs-primary);
    --listing-primary-terms: var(--bs-gray-600);
    --listing-primary-deposit: url('../../images/icons/deposit.svg');

    /* Listings - Secondary */
    --listing-secondary-background: url('../../images/backgrounds/bingoport-listing-alt.webp')
        top center/100% 100%;
    --listing-secondary-color: var(--bs-white);
    --listing-secondary-bold: var(--bs-gold);
    --listing-secondary-link: var(--bs-sky);
    --listing-secondary-terms: var(--bs-gray-100);
    --listing-secondary-deposit: url('../../images/icons/deposit-white.svg');

    /* Rewards  - Category */
    --category-background: url('../../images/backgrounds/bingoport-category.webp');
    --category-background-s: url('../../images/backgrounds/bingoport-category-s.webp');

    /* Rewards - Primary */
    --reward-primary-background: url('../../images/backgrounds/bingoport-reward.webp');
    --reward-primary-color: var(--bs-black);
    --reward-primary-price: var(--bs-red-light);

    /* Rewards - Secondary */
    --reward-secondary-background: url('../../images/backgrounds/bingoport-reward-alt.webp');
    --reward-secondary-color: var(--bs-white);
    --reward-secondary-price: var(--bs-gold);
}
