.lander {
    position: relative;
    border: none;
    border-radius: 0;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 0 0.25rem 0.125rem rgba(0, 0, 0, 0);
    transition: box-shadow 0.3125s ease-in-out;

    @media only screen and (max-width: 1199px) {
        margin-left: calc(-0.5 * var(--spacer-4));
        margin-right: calc(-0.5 * var(--spacer-4));
    }

    @media only screen and (min-width: 1200px) {
        background-image: url('../../images/backgrounds/lander-l.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        > .row {
            max-width: 90rem;
            margin-left: auto;
            margin-right: auto;
        }
    }

    &:hover {
        box-shadow: 0 0 0.25rem 0.125rem rgba(0, 0, 0, 0.125);
    }

    .card-body {
        padding-top: 0.75rem;
        padding-bottom: 2rem;
        padding-left: 2rem;
        padding-right: 2rem;
        background-color: var(--bs-primary-highlight);
        background-image: url('../../images/backgrounds/lander-s.webp');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: var(--bs-black);

        @media only screen and (min-width: 768px) {
            padding-top: 1.5rem;
            background-image: url('../../images/backgrounds/lander-m.webp');
        }

        @media only screen and (min-width: 1200px) {
            background-color: transparent;
            background-image: none;
        }
    }

    .card-body p:last-of-type {
        margin-bottom: 0;
    }

    .card-body label {
        font-weight: 300 !important;
        color: inherit !important;
    }

    .card-body .lead {
        font-size: 1.125rem;

        @media only screen and (min-width: 768px) {
            font-size: 1.5rem;
        }

        @media only screen and (min-width: 1200px) {
            font-size: 1.5rem;
        }
    }
}
