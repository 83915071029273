.notification-widget {
    z-index: var(--zindex-popover);
    display: flex;
    flex-direction: column;
    width: 12.5rem;
    background-color: var(--bs-white);
    border: 0.0625rem solid var(--bs-gray-500);
    border-radius: 0.5rem;
    box-shadow: var(--box-shadow);
    transition: transform var(--transition-base-duration)
        var(--transition-base-timing);
    transform-origin: top center;
    transform: scaleY(0);

    @media screen and (min-width: 768px) {
        width: 17.5rem;
    }

    .navbar & {
        position: absolute;
        top: calc(100% + 0.5rem);
        right: -3rem;
    }

    &.collapsing,
    &.show {
        position: absolute !important;
        display: block !important;
        height: auto !important;
    }

    &.show {
        transform: scaleY(1);
    }

    &::before,
    &::after {
        position: absolute;
        width: 0;
        height: 0;
        content: '';
    }

    &::before {
        top: -0.875rem;
        right: 3.625rem;
        z-index: 1;
        border-left: 0.625rem solid transparent;
        border-right: 0.625rem solid transparent;
        border-bottom: 0.875rem solid var(--bs-gray-500);

        @media only screen and (min-width: 576px) {
            right: 4.125rem;
        }
    }

    &::after {
        top: -0.75rem;
        right: 3.75rem;
        z-index: 2;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        border-bottom: 0.75rem solid var(--bs-white);

        @media only screen and (min-width: 576px) {
            right: 4.25rem;
        }
    }

    header,
    footer {
        padding: 0.5rem;
    }

    footer {
        padding: 0.5rem;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 0;
        @media only screen and (min-width: 576px) {
            font-size: 1.125rem;
        }
    }
}
