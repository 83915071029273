[data-rewards-theme='bingoport-com'] {
    /* Theme RGB values */
    --bs-primary-rgb: 134, 56, 229;
    --bs-primary-light-rgb: 152, 82, 243;
    --bs-primary-highlight-rgb: 244, 235, 254;
    --bs-primary-contrast-rgb: var(--bs-white-rgb);

    /* Theme color variables */
    --bs-primary: rgb(var(--bs-primary-rgb));
    --bs-primary-light: rgb(var(--bs-primary-light-rgb));
    --bs-primary-highlight: rgb(var(--bs-primary-highlight-rgb));
    --bs-primary-contrast: rgb(var(--bs-primary-contrast-rgb));

    /* Links */
    --bs-link-color: var(--bs-blue-light);

    /* Gradient colour variables */
    --bs-gradient-primary: linear-gradient(
        180deg,
        var(--bs-primary-light) 0%,
        rgba(80, 35, 138, 1) 100%
    );

    --bs-gradient-secondary: linear-gradient(
        180deg,
        rgba(var(--bs-white-rgb), 80%) 0%,
        rgba(var(--bs-white-rgb), 40%) 100%
    );

    /* Headings - Landing */
    --heading-landing-color: inherit;
    --heading-landing-bold-color: var(--bs-green-neon);

    /* Font sizes */
    --font-size-md: 1rem;

    @media only screen and (min-width: 768px) {
        --font-size-md: 1rem;
    }

    @media only screen and (min-width: 992px) {
        --font-size-md: 1rem;
    }

    /* Listings - Primary */
    --listing-primary-background: url('../../images/backgrounds/bingoport-listing.webp')
        top center/100% 100%;
    --listing-primary-color: var(--bs-black);
    --listing-primary-bold: var(--bs-red-light);
    --listing-primary-link: var(--bs-primary);
    --listing-primary-terms: var(--bs-gray-600);
    --listing-primary-deposit: url('../../images/icons/deposit.svg');

    /* Listings - Secondary */
    --listing-secondary-background: url('../../images/backgrounds/bingoport-listing-alt.webp')
        top center/100% 100%;
    --listing-secondary-color: var(--bs-white);
    --listing-secondary-bold: var(--bs-gold);
    --listing-secondary-link: var(--bs-sky);
    --listing-secondary-terms: var(--bs-gray-100);
    --listing-secondary-deposit: url('../../images/icons/deposit-white.svg');

    /* Rewards  - Category */
    --category-background: url('../../images/backgrounds/bingoport-category.webp');
    --category-background-s: url('../../images/backgrounds/bingoport-category-s.webp');

    /* Rewards - Primary */
    --reward-primary-background: transparent;
    --reward-primary-color: var(--bs-white);
    --reward-primary-price: var(--bs-gold);

    /* Rewards - Secondary */
    --reward-secondary-background: transparent;
    --reward-secondary-color: var(--bs-white);
    --reward-secondary-price: var(--bs-gold);
}
