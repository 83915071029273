.form-button {
    position: relative;
    display: inline-block;
}

.form-button-input {
    position: absolute;
    top: 0;
    left: 0;
    display: none;

    &:disabled:disabled ~ .form-button-label {
        cursor: not-allowed;
    }
}

.form-button-label {
    padding: 0.25rem 0.75rem;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-weight: 500;
    border: 0.0625rem solid;
    border-radius: 0.5rem;
    background-origin: border-box;
    background-repeat: no-repeat;
    cursor: pointer;
}

.form-button-sm .form-button-label {
    padding: 0.125rem 0.25rem;
    font-size: 1rem;
    font-weight: 300;
    border-radius: 0.25rem;
}

.form-button-primary {
    .form-button-label {
        background-color: var(--bs-primary);
        border-color: transparent;
        color: var(--bs-white);
    }

    .form-button-input:checked ~ .form-button-label,
    .form-button-input:checked:disabled ~ .form-button-label {
        background: var(--bs-primary-highlight);
        border-color: transparent;
        color: var(--bs-primary);
    }

    .form-button-input:disabled ~ .form-button-label {
        background-image: linear-gradient(
            0deg,
            var(--bs-gray-100) 0%,
            var(--bs-gray-100) 100%
        );
        background-origin: border-box;
        background-repeat: no-repeat;
        border-color: var(--bs-gray-100);
        color: var(--bs-gray-400);
    }
}

.form-button-secondary {
    .form-button-label {
        background-color: var(--bs-white);
        border-color: var(--bs-gray-500);
        color: var(--bs-gray-500);
    }

    .form-button-input:checked ~ .form-button-label,
    .form-button-input:checked:disabled ~ .form-button-label {
        background: rgb(128, 20, 29);
        background-image: linear-gradient(
            0deg,
            rgba(128, 20, 29, 1) 0%,
            rgba(185, 26, 41, 1) 100%
        );
        border-color: rgba(128, 20, 29, 1);
        color: var(--bs-white);
    }

    .form-button-input:disabled ~ .form-button-label {
        background: var(--bs-gray-200);
        background-image: linear-gradient(
            0deg,
            var(--bs-gray-300) 0%,
            var(--bs-gray-200) 100%
        );
        border-color: var(--bs-gray-500);
        color: var(--bs-gray-500);
    }
}
