.welcome-offer {
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 35% 1fr;
    grid-gap: 1rem 2rem;

    @media only screen and (min-width: 768px) {
        grid-template-columns: 70% 1fr;
    }

    @media only screen and (min-width: 1200px) {
        grid-template-columns: 8% 56% 8% 1fr 8%;
        grid-template-rows: auto 1fr;
        grid-gap: 1rem 0;
    }
}

.welcome-offer__cell {
    @media (max-width: 767px) {
        &:nth-child(1) {
            grid-column: 1 / 3;
            grid-row: 1 / 1;
        }
        &:nth-child(2) {
            grid-row: 2 / 3;
        }
        &:nth-child(3) {
            grid-row: 2 / 3;
        }
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        &:nth-child(1) {
            grid-row: 1 / 3;
        }
        &:nth-child(2) {
            grid-column: 2 / 3;
            grid-row: 1 / 2;
        }
        &:nth-child(3) {
            grid-column: 2 / 3;
            grid-row: 2 / 3;
        }
    }

    @media (min-width: 1200px) {
        &:nth-child(1) {
            grid-column: 2 / 3;
            grid-row: 1 / 3;
        }
        &:nth-child(2) {
            grid-column: 4 / 5;
            grid-row: 1 / 2;
        }
        &:nth-child(3) {
            grid-column: 4 / 5;
            grid-row: 2 / 3;
        }
    }
}

.welcome-offer__cell:nth-child(3) {
    container: welcome-offer / inline-size;
    text-align: center;

    h2 {
        margin-top: 0;
        color: var(--bs-red-summer);
        margin-bottom: 0.5rem;
        font-size: 2rem;
        font-weight: 800;
        line-height: 0.875;
        text-transform: uppercase;

        @container welcome-offer (min-width: 0) {
            margin-bottom: 2cqw;
            font-size: 16cqw;
        }
    }

    p {
        font-weight: 400;
        line-height: 1;

        @container welcome-offer (min-width: 0) {
            font-size: 10cqw;
        }
    }

    .btn {
        font-size: 1.125rem;
    }

    @media only screen and (min-width: 768px) {
        .btn {
            padding: 0.5rem;
            font-size: 1rem;

            @container welcome-offer (min-width: 0) {
                padding: 4cqw;
                font-size: 8cqw;
            }
        }
    }
}
