[data-rewards-theme='bonuskings-com'] {
    /* Theme RGB values */
    --bs-primary-rgb: 59, 232, 176;
    --bs-primary-light-rgb: 91, 231, 186;
    --bs-primary-highlight-rgb: 245, 251, 249;
    --bs-primary-contrast-rgb: var(--bs-black-rgb);

    /* Theme color variables */
    --bs-primary: rgb(var(--bs-primary-rgb));
    --bs-primary-light: rgb(var(--bs-primary-light-rgb));
    --bs-primary-highlight: rgb(var(--bs-primary-highlight-rgb));
    --bs-primary-contrast: rgb(var(--bs-primary-contrast-rgb));

    /* Links */
    --bs-link-color: var(--bs-secondary);
    --bs-link-decoration: underline;

    /* Gradient - Primary */
    --bs-gradient-primary: linear-gradient(
        180deg,
        rgba(91, 231, 186, 1) 0%,
        rgba(58, 220, 168, 1) 100%
    );

    /* Headings - Landing */
    --heading-landing-color: inherit;
    --heading-landing-bold-color: inherit;

    /* Listings - Primary */
    --listing-primary-background: linear-gradient(
        0deg,
        rgba(5, 1, 32, 1) 0%,
        rgba(133, 0, 185, 1) 60%,
        rgba(227, 73, 239, 1) 100%
    );
    --listing-primary-color: var(--bs-white);
    --listing-primary-bold: var(--bs-gold);
    --listing-primary-link: var(--bs-sky);
    --listing-primary-terms: var(--bs-gray-100);
    --listing-primary-deposit: url('../../images/icons/deposit-white.svg');

    /* Listings - Secondary */
    --listing-secondary-background: linear-gradient(
        0deg,
        rgba(53, 66, 80, 1) 0%,
        rgba(26, 33, 53, 1) 40%,
        rgba(0, 0, 0, 1) 100%
    );
    --listing-secondary-color: var(--listing-primary-color);
    --listing-secondary-bold: var(--listing-primary-bold);
    --listing-secondary-link: var(--listing-primary-link);
    --listing-secondary-terms: var(--listing-primary-terms);
    --listing-secondary-deposit: var(--listing-primary-deposit);

    /* Rewards  - Category */
    --category-background: url('../../images/backgrounds/bonuskings-category.webp');
    --category-background-s: url('../../images/backgrounds/bonuskings-category-s.webp');

    /* Rewards - Primary */
    --reward-primary-background: url('../../images/backgrounds/bonuskings-reward.webp');
    --reward-primary-color: var(--bs-white);
    --reward-primary-price: var(--bs-primary);

    /* Rewards - Secondary */
    --reward-secondary-background: url('../../images/backgrounds/bonuskings-reward-alt.webp');
    --reward-secondary-color: var(--reward-primary-color);
    --reward-secondary-price: var(--reward-primary-price);

    /* Rewards */
    --reward-primary-contrast: 'white';
    --reward-primary-background: url('../../images/backgrounds/bonuskings-reward.webp');
    --reward-secondary-contrast: 'white';
    --reward-secondary-background: url('../../images/backgrounds/bonuskings-reward-alt.webp');
    --category-background: url('../../images/backgrounds/bonuskings-category.webp');
    --category-background-s: url('../../images/backgrounds/bonuskings-category-s.webp');
}
