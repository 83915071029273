html,
body {
    min-width: 100%;
    min-height: 100%;
}

body {
    font-family: var(--bs-body-font-family);
    font-size: var(--bs-body-font-size);
    font-weight: var(--bs-body-font-weight);
    line-height: var(--bs-body-line-height);

    [data-bs-theme='dark'] & {
        --bs-body-bg: var(--bs-black);
        --bs-body-contrast: var(--bs-gray-900);
        --bs-body-color: var(--bs-white);
        --bs-body-bg-rgb: var(--bs-black-rgb);
        --bs-body-contrast-rgb: var(--bs-gray-900-rgb);
        --bs-body-color-rgb: var(--bs-white-rgb);
    }
}
