.jumbotron {
    position: relative;
    z-index: 0;
}

.jumbotron__title {
    color: var(--bs-white);
    margin: 0;
    font-size: calc(48 / 430 * 100vw);
    font-weight: 900;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    @media only screen and (min-width: 768px) {
        position: absolute;
        top: 0.5rem;
        left: 0;
        right: 0;
        z-index: 2;
        font-size: calc(42 / 800 * 100vw);
    }

    @media only screen and (min-width: 1200px) {
        font-size: 4rem;
    }
}

.jumbotron__inner {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    aspect-ratio: 5 / 2;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media only screen and (min-width: 768px) {
        aspect-ratio: 7 / 2;
    }

    @media only screen and (min-width: 1200px) {
        padding-top: 1rem;
        padding-bottom: 4%;
    }
}
