.transactions {
    margin-top: -1rem;
    margin-left: calc(-0.5 * var(--spacer-4));
    margin-right: calc(-0.5 * var(--spacer-4));
    background-color: rgba(var(--bs-black-rgb), 10%);

    @media only screen and (min-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }

    table {
        width: 100%;
    }

    td {
        padding: 0.75rem 0;
    }

    tr:first-of-type {
        border-top: none;
    }
}

.transactions__date {
    padding-bottom: 0;
    color: var(--bs-gray-400);
    font-size: 0.9375rem;
    font-weight: 400;
    text-align: left;
    border-top: 0.0625rem solid var(--bs-gray-300);
}

.transactions__item {
    font-weight: 400;

    td:first-child {
        width: 3.25rem;
        padding-right: 0;
    }

    td:last-child {
        text-align: right;
    }
}

.transactions__item--unread {
    font-weight: 500;
}

.transactions__icon {
    display: flex;
    width: 2.25rem;
    padding-top: 2.25rem;
    background-repeat: no-repeat;
    background-position: center;
}

.transactions__icon--pending {
    background-color: #c4c4c4;
    background-image: url('../../images/icons/transaction-pending.svg');
    background-size: 1.75rem;
}

.transactions__icon--in {
    background-color: var(--bs-success);
    background-image: url('../../images/icons/transaction-in.svg');
    background-size: 1.875rem;
}

.transactions__icon--out {
    background-color: var(--bs-danger);
    background-image: url('../../images/icons/transaction-out.svg');
    background-size: 1.875rem;
}

.transactions__amount--out {
    color: #9b2828;
}

.transactions__menu {
    position: relative;
    margin-top: -1rem;
    display: flex;
}

.transactions__menu__button {
    display: block;
    width: 100%;
    padding: 1rem;
    background-color: rgba(var(--bs-black-rgb), 10%);
    text-align: center;

    &.active,
    [data-bs-theme='dark'] &.active {
        background-color: transparent;
    }
}
