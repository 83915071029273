/* Background - Image (Bingo/Lander/Play/Slots) */
.bg-image {
    background-color: var(--bs-primary);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-image-fixed {
    background-attachment: fixed;
}

.bg-image-top {
    background-position: top center;
}

.bg-image-bottom {
    background-position: bottom center;
}

/* Background - Image (Bingo/Lander/Play/Slots) */
.bg-bingo,
.bg-landing,
.bg-play,
.bg-slots {
    &,
    .page > header {
        background-attachment: fixed;
        background-image: var(--bs-body-image);
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.bg-bingo,
.bg-landing {
    --bs-body-image: url('https://cdn.zentrl.io/image/background/bingo-s.webp');
}

.bg-bingo {
    @media only screen and (min-width: 768px) {
        --bs-body-image: url('https://cdn.zentrl.io/image/background/bingo-l.webp');
    }
}

.bg-landing {
    @media only screen and (min-width: 768px) {
        --bs-body-image: url('https://cdn.zentrl.io/image/background/bingo-m.webp');
    }
}

/* Background - Image (Play) */
.bg-play {
    --bs-body-image: url('https://cdn.zentrl.io/image/background/play-s.webp');

    @media only screen and (min-width: 768px) {
        --bs-body-image: url('https://cdn.zentrl.io/image/background/play-m.webp');
    }

    @media only screen and (min-width: 1200px) {
        --bs-body-image: url('https://cdn.zentrl.io/image/background/play-l.webp');
    }
}

/* Background - Image (slots) */
.bg-slots {
    --bs-body-image: url('https://cdn.zentrl.io/image/background/slots-s.webp');

    @media only screen and (min-width: 768px) {
        --bs-body-image: url('https://cdn.zentrl.io/image/background/slots-m.webp');
    }

    @media only screen and (min-width: 1200px) {
        --bs-body-image: url('https://cdn.zentrl.io/image/background/slots-l.webp');
    }
}

/* Background - Colors */
.bg-gray-100 {
    background-color: var(--bs-gray-100);
}

.bg-gray-200 {
    background-color: var(--bs-gray-200);
}

.bg-gray-300 {
    background-color: var(--bs-gray-300);
}

.bg-gray-400 {
    background-color: var(--bs-gray-400);
}

.bg-gray-500 {
    background-color: var(--bs-gray-500);
}

.bg-gray-600 {
    background-color: var(--bs-gray-600);
}

.bg-gray-700 {
    background-color: var(--bs-gray-700);
}

.bg-gray-800 {
    background-color: var(--bs-gray-800);
}

.bg-gray-900 {
    background-color: var(--bs-gray-900);
}

.bg-red {
    background-color: var(--bs-red);
}

.bg-red-light {
    background-color: var(--bs-red-light);
}

.bg-red-summer {
    background-color: var(--bs-red-summer);
}

.bg-orange {
    background-color: var(--bs-orange);
}

.bg-gold {
    background-color: var(--bs-gold);
}

.bg-yellow {
    background-color: var(--bs-yellow);
}

.bg-yellow-summer {
    background-color: var(--bs-yellow-summer);
}

.bg-yellow-highlight {
    background-color: var(--bs-yellow-highlight);
}

.bg-lemon {
    background-color: var(--bs-lemon);
}

.bg-green {
    background-color: var(--bs-green);
}

.bg-green-light {
    background-color: var(--bs-green-light);
}

.bg-sky {
    background-color: var(--bs-sky);
}

.bg-cyan {
    background-color: var(--bs-cyan);
}

.bg-blue {
    background-color: var(--bs-blue);
}

.bg-blue-alt {
    background-color: var(--bs-blue-alt);
}

.bg-blue-light {
    background-color: var(--bs-blue-light);
}

.bg-steel {
    background-color: var(--bs-steel);
}

.bg-primary {
    background-color: var(--bs-primary);
}

.bg-primary-light {
    background-color: var(--bs-primary-light);
}

.bg-primary-highlight {
    background-color: var(--bs-primary-highlight);
}

.bg-primary-contrast {
    background-color: var(--bs-primary-contrast);
}

.bg-white {
    background-color: var(--bs-white);
}

.bg-black {
    background-color: var(--bs-black);
}

.bg-white-rgba {
    background-color: var(--bs-white-rgba);
}

.bg-black-rgba {
    background-color: var(--bs-black-rgba);
}

.bg-primary-gradient {
    background-image: var(--bs-gradient-primary);
}

.bg-secondary-gradient {
    background-image: var(--bs-gradient-secondary);
}
